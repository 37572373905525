const setupContinueWithEmail = formId => {
  const formContainer = document.getElementById(formId);

  if (!formContainer) {
    return;
  }

  const continueWithEmail = document.getElementById('continue-with-email');
  const continueWithEmailChevron = continueWithEmail.querySelector('.continue-with-email-chevron');

  if (!continueWithEmail) {
    formContainer.style.visibility = 'visible';
    formContainer.style.maxHeight = 'max-content';
    return;
  }

  continueWithEmail.addEventListener('click', () => {
    const isCollapsed = formContainer.style.maxHeight === '0px';
    formContainer.style.maxHeight = isCollapsed ? 'max-content' : '0px';
    formContainer.style.visibility = isCollapsed ? 'visible' : 'hidden';
    continueWithEmailChevron.classList.remove('rotate-90');
    continueWithEmailChevron.classList.remove('-rotate-90');
    continueWithEmailChevron.classList.add(isCollapsed ? '-rotate-90' : 'rotate-90');
  });

  // Automatically expand the form if the email is present
  const expandEmailInput = formContainer.querySelector('#expand_email_form');
  if (expandEmailInput && expandEmailInput.value === true) {
    continueWithEmail.click();
  }
};

export { setupContinueWithEmail };
